<template>
  <div
    class="customer_manager"
    v-loading="loading"
    element-loading-text="加载中..."
    element-loading-spinner="el-icon-loading"
  >
    <div class="top_card">
      <el-form label-width="100px">
        <el-form-item label="所在地区：">
          <!-- <CitySelectTwo
            ref="child"
            :province="queryInfo.province"
            :city="queryInfo.city"
            @searchProvince="searchProvince"
            @searchCity="searchCity"
          /> -->
          <city-select
            ref="child"
            :province="queryInfo.province"
            :city="queryInfo.city"
            :district="queryInfo.district"
          
            @searchCity="searchCity"
            @searchDistrict="searchDistrict"
          />
        </el-form-item>
        <el-form-item label="客户等级：">
          <div class="radio_group">
            <el-radio-group v-model="queryInfo.clientGrade" @change="search">
              <el-radio-button label="">全部</el-radio-button>
              <el-radio-button label="A类客户">A类客户</el-radio-button>
              <el-radio-button label="B类客户">B类客户</el-radio-button>
              <el-radio-button label="C类客户">C类客户</el-radio-button>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label-width="100px" label="行业标签：">
          <dic-tag-button
            :code.sync="industryName"
            query-name="industryCategoryList"
            name="赋能行业"
            tag="行业"
            @onChange="onChange"
          />
        </el-form-item>
        <!-- <el-form-item label="筛选条件：" class="filterInfo">
          <el-tag closable @close="closeTag()">
            国民经济标签组：{{
              queryInfo.industryCategoryList.length ? queryInfo.industryCategoryList[0] : '不限'
            }}
            <span class="circle" v-if="queryInfo.industryCategoryList.length"
              >+{{ queryInfo.industryCategoryList.length }}</span
            >
          </el-tag>
        </el-form-item> -->
        <div class="row" style="justify-content: space-between">
          <div class="btn_list">
            <el-button type="primary" v-if="$hasPerms('company:insert')" @click="add"
              >新增</el-button
            >
            <!-- @click="manyImport" -->
            <el-upload
              v-if="$hasPerms('company:import')"
              action
              :http-request="upload"
              :show-file-list="false"
              :on-success="handleSuccess"
              :before-upload="beforeUpload"
              v-loading="uploading"
            >
              <el-button plain>批量导入</el-button>
            </el-upload>
            <!-- <el-button @click="checkClick" plain>批量报备</el-button> -->
            <el-button v-if="$hasPerms('company:f')" plain @click="showSetManagerDialog"
              >批量分配经理</el-button
            >
            <el-button v-if="$hasPerms('company:f')" plain @click="setClientClns"
              >批量取消分配</el-button
            >
            <el-button v-if="$hasPerms('company:level')" plain @click="setClientGrade"
              >批量设置等级</el-button
            >
          </div>
          <div v-if="$hasPerms('company:f')">
            <el-checkbox
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              @change="handleCheckAllChange"
            >
              全选
            </el-checkbox>
          </div>
          <div class="check" v-if="$hasPerms('company:f')">
            <el-checkbox @change="showNotAllocate"> 只查看未分配企业 </el-checkbox>
          </div>
          <div style="width: 200px" v-else>
            <span></span>
          </div>
          <!-- 右边搜索 -->
          <div class="search_right">
            <el-input
              placeholder="请输入企业/客户经理名称"
              v-model="queryInfo.keys"
              class="input-with-select"
              clearable
            >
              <el-button slot="append" @click="search" type="primary">搜索</el-button>
            </el-input>
          </div>
        </div>
      </el-form>
    </div>
    <div class="row">
      <div class="row_txt">
        <span>共</span>
        <span class="color1">{{ total }}</span>
        <span>家企业</span>
      </div>
      <div class="sort">
        <span @click="handleSort(sortVal)" >按新增时间</span>
        <div class="sort_icon">
          <div
            class="triangle_top_box"
            :class="isActive === 'up' ? 'top_active' : ''"
            @click="handleSort('up')"
          ></div>
          <div
            class="triangle_bottom_box"
            :class="isActive === 'down' ? 'bottom_active' : ''"
            @click="handleSort('down')"
          ></div>
        </div>
      </div>
    </div>
    <div class="card_list">
      <div class="card_item" v-for="(item, index) in list" :key="index">
        <div class="card_left">
          <div class="item_checkbox">
            <el-checkbox
              @change="latechange($event, item)"
              v-model="item.checked1"
              label=""
            ></el-checkbox>
          </div>
          <!-- 报备失败图标 -->
          <div
            class="po_reportingStatus"
            v-if="item.reportingStatus == 3 || item.reportingStatus == 5"
          >
            <img
              src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img/%E6%8A%A5%E5%A4%87%E5%A4%B1%E8%B4%A5.png"
              alt=""
            />
          </div>
          <!-- 报备成功图标 -->
          <div class="po_reportingStatus" v-if="item.reportingStatus == 2">
            <img
              src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img/%E6%9C%80%E4%BD%B3%E4%BA%BA%E6%B0%94%E5%A5%96%205.png"
              alt=""
            />
          </div>
          <!-- 重新报备图标 -->
          <div class="po_reportingStatus" v-if="item.reportingStatus == 6">
            <img
              src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img/%E6%9C%80%E4%BD%B3%E4%BA%BA%E6%B0%94%E5%A5%96%204.png"
              alt=""
            />
          </div>
          <div class="img_box">
            <img v-if="item.companyLogo" :src="item.companyLogo" alt="" />
            <img v-else src="../../assets/image/enterprise-default-logo.png" alt="" />
            <div v-if="item.clientGrade" class="tag_box" :class="getTagClass(item.clientGrade)">
              {{ item.clientGrade }}
            </div>
          </div>
          <div class="info_box">
            <div v-if="item.reportingStatus == 1 || item.reportingStatus == 4" class="reporting">
              <span>该企业报备中...</span>
            </div>
            <div v-if="item.reportingStatus == 6" class="reportingAgin">
              <span>重新报备中...</span>
            </div>
            <div v-if="item.reportingStatus == 2" class="success">
              <span>报备成功</span>
            </div>
            <div v-if="item.reportingStatus == 3 || item.reportingStatus == 5" class="fail">
              <span>报备失败</span>
            </div>
            <div v-if="item.establishWechatGroup == 1" class="reportingEnd">
              <span>已建群</span>
            </div>
            <div class="row">
              <div class="enterprise_name" @click="toEnterpriseDetails(item, 0)">
                {{ item.companyFullName }}
              </div>
              <div class="type_tag" :class="getEnterpriseScale(item.enterpriseScale)">
                <span v-if="item.enterpriseScale == 'L(大型)' || item.enterpriseScale == '大型'"
                  >大型</span
                >
                <span v-if="item.enterpriseScale == 'M(中型)' || item.enterpriseScale == '中型'"
                  >中型</span
                >
                <span v-if="item.enterpriseScale == 'S(小型)' || item.enterpriseScale == '小型'"
                  >小型</span
                >
                <span v-if="item.enterpriseScale == 'XS(微型)' || item.enterpriseScale == '微型'"
                  >微型</span
                >
              </div>
            </div>
            <div class="row">
              <div v-if="item.companyLabelList && item.companyLabelList.length">
                <div class="tag_list" v-if="item.companyLabelList.length > 3">
                  <div v-for="(i1, i2) in item.tagList" :key="i2">
                    <div class="tag_item" v-if="i2 < 3">
                      {{ i1 }}
                    </div>
                  </div>
                  <div
                    class="show_more"
                    @mouseenter="item.isShowTag = true"
                    @mouseleave="item.isShowTag = false"
                  >
                    {{ item.companyLabelList.length + '...' }}
                    <ul
                      class="show_tag_box"
                      @mouseenter="item.isShowTag = true"
                      @mouseleave="item.isShowTag = false"
                      v-show="item.isShowTag"
                    >
                      <li v-for="(i1, i2) in item.tagList" :key="i2">
                        <div class="tag">
                          {{ i1 }}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div v-else>
                  <div class="tag_list" v-if="item.companyLabelList[0] != 'null'">
                    <div v-for="(i1, i2) in item.companyLabelList" :key="i2">
                      <div class="tag_item">
                        {{ i1 }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <span v-if="item.legalPerson" style="margin-right: 5px">{{ item.legalPerson }}</span>
              <span v-if="item.companyPhone" style="margin-right: 5px">{{
                item.companyPhone
              }}</span>
              <span
                ><span v-if="item.city"> {{ item.city }}</span>
                <span v-if="item.district"> - {{ item.district }}</span></span
              >

              <!-- <span class="website" @click="toOfficialWebsite(item.officialWebsite)">{{
                item.officialWebsite
              }}</span> -->
            </div>
          </div>
        </div>
        <div class="card_right">
          <div class="box_list">
            <div class="item" @click="toEnterpriseDetails(item, 1)">
              <div class="num">{{ item.contactPerson ? item.contactPerson : 0 }}</div>
              <div class="row">
                <span class="txt">联络人</span>
              </div>
            </div>

            <div
              class="item"
              :style="isred == '1' && item.serviceCount ? 'color:#ff0000' : ''"
              @click="toEnterpriseDetails(item, 4)"
            >
              <div class="num" :style="isred == '1' && item.serviceCount ? 'color:#ff0000' : ''">
                {{ item.serviceCount ? item.serviceCount : 0 }}
              </div>
              <div class="row">
                <!-- <div class="icon_box">
                  <i class="iconfont icon-jr-icon-user1"></i>
                </div> -->
                <span>服务记录</span>
              </div>
            </div>
            <div class="item" @click="toEnterpriseDetails(item, 2)">
              <div class="num">{{ item.caseCount ? item.caseCount : 0 }}</div>
              <div class="row">
                <!-- <div class="icon_box">
                  <i class="iconfont icon-jr-icon-user1"></i>
                </div> -->
                <span>场景信息</span>
              </div>
            </div>
            <div
              class="item"
              :style="isred == '2' && item.planNum ? 'color:#ff0000' : ''"
              @click="toEnterpriseDetails(item, 6)"
            >
              <div class="num" :style="isred == '2' && item.planNum ? 'color:#ff0000' : ''">
                {{ item.planNum ? item.planNum : 0 }}
              </div>
              <div class="row">
                <span>案例数据</span>
              </div>
            </div>
            <div
              class="item"
              :style="isred == '3' && item.diagnosisNum ? 'color:#ff0000' : ''"
              @click="toEnterpriseDetails(item, 3)"
            >
              <div class="num" :style="isred == '3' && item.diagnosisNum ? 'color:#ff0000' : ''">
                {{ item.diagnosisNum ? item.diagnosisNum : 0 }}
              </div>
              <div class="row">
                <!-- <div class="icon_box">
                  <i class="iconfont icon-jr-icon-user1"></i>
                </div> -->
                <span>潜在线索</span>
              </div>
            </div>
          </div>
          <div class="btn_box">
            <div class="set_manager" v-if="$hasPerms('company:f')">
              <div class="middle_icon_box">
                <img v-if="item.notAllocate" :src="item.customerHead" alt="" />
                <img v-else src="@/assets/image/wait-set-manager.png" alt="" />
              </div>
              <span
                v-if="item.notAllocate"
                class="manager_name color1"
                @click="showSetManagerDialog(item.id)"
              >
                {{ item.customerName }}
              </span>
              <span v-else class="wait_set color3" @click="showSetManagerDialog(item.id)"
                >待分配</span
              >
            </div>
<!-- 
            <div>
              <el-button
                style="width: 100px; text-align: center"
                type="info"
                @click="records(item)"
                plain
                >诊断记录
                <span v-if="item.diagnoseCount">({{ item.diagnoseCount }})</span>
              </el-button>
            </div> -->
            <el-popover
              style="margin-right: 10px; margin-left: 10px"
              v-if="item.reportingStatus == 3"
              placement="top"
              width="380"
              trigger="click"
            >
              <p>
                ①报备中情况一：没有机构报备A企业，则企业展示报备中的状态情况二：多家机构报备A企业，则按照报备规则展示企业报备中，如果A企业被前一位机构关联上，则展示报备失败（备注理由：该企业已被其他平台关联，请继续新增其他企业）
              </p>
              <p>②报备成功：机构报备在两个月之内，智参用户有在小程序绑定这家企业，则报备成功</p>
              <p>
                ③报备失败：A机构报备在两个月之内，智参用户没有在小程序绑定这家企业，则报备失败；如果有B、C机构报备此企业，则按新增正序排列至B机构，B机构企业展示报备中的状态（如果B机构移除报备企业），则排列至C机构报备之后需要总后台审核才能正式进入报备流程
              </p>
              <el-button slot="reference">查看报备规则</el-button>
            </el-popover>
            <div class="btn_list">
              <span
                v-if="$hasPerms('company:level')"
                @click="setClientGrade(item.id)"
                class="color1"
                >设置等级</span
              >
              <span
                v-if="$hasPerms('company:detail')"
                @click="toEnterpriseDetails(item, 0)"
                class="color1"
                >详情</span
              >
              <span
                v-if="$hasPerms('company:freeze')"
                @click="setFreeze(item.id, item.isFreeze)"
                class="color2"
                >{{ item.isFreeze ? '解冻' : '冻结' }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <!-- 分配客户经理弹窗 -->
    <el-dialog
      title="分配客户经理"
      :visible.sync="setManagerDialogVisible"
      @closed="setManagerDialogClosed"
      :close-on-click-modal="false"
      width="60%"
    >
      <div class="dialog_box">
        <div class="row">
          <city-select
            ref="child"
            :province="managerQueryInfo.provinces"
            :city="managerQueryInfo.city"
            :district="managerQueryInfo.district"
            @searchProvince="searchProvinceManager"
            @searchCity="searchCityManager"
            @searchDistrict="searchDistrictManager"
          />
        </div>
        <div class="row">
          <div class="search_right">
            <el-input
              placeholder="请输入姓名/电话"
              v-model="managerQueryInfo.keys"
              class="input-with-select"
              style="width: 440px"
            >
              <el-button slot="append" @click="searchCustomerManager">搜索</el-button>
            </el-input>
          </div>
        </div>
        <div class="table">
          <el-table
            ref="managerTable"
            :data="managerTableList"
            style="width: 100%"
            :header-cell-style="{ background: '#F8F9FB' }"
            @select="handleSelect"
            @sort-change="sortChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="用户头像" min-width="120" align="center">
              <template v-slot="{ row }">
                <img :src="row.customerHead" alt="" class="customer_head" />
              </template>
            </el-table-column>
            <el-table-column prop="customerName" label="姓名" min-width="120"> </el-table-column>
            <el-table-column label="所在地区" min-width="120">
              <template v-slot="{ row }">
                <span>{{ row.provinces }}</span>
                <span>{{ row.city }}</span>
                <span>{{ row.district }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="customerPhone" label="手机号" min-width="120"> </el-table-column>
            <el-table-column prop="companyCount" label="关联企业" min-width="120" sortable>
            </el-table-column>
            <el-table-column prop="loginDate" label="最近登录时间" min-width="140" sortable>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="new_page">
        <el-pagination
          :current-page="managerQueryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="managerQueryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="managerTableTotal"
          @size-change="handleSizeChangeManager"
          @current-change="handleCurrentChangeManager"
        />
      </div>
      <div class="dialog_footer">
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="saveSetManager">保存</el-button>
          <el-button @click="setManagerDialogClosed">取 消</el-button>
        </span>
      </div>
    </el-dialog>
    <AddEnterprise ref="addRef" :echo="echo" @search="search" />
    <!-- 分配客户经理弹窗 -->
    <el-dialog
      title="分配客户经理"
      :visible.sync="setClientGradeDialogVisible"
      @closed="setClientGradeDialogClosed"
      :close-on-click-modal="false"
      width="480px"
    >
      <div class="dialog_box">
        <div class="row">
          <el-radio-group v-model="clientGrade">
            <el-radio :label="'A类客户'">A类客户</el-radio>
            <el-radio :label="'B类客户'">B类客户</el-radio>
            <el-radio :label="'C类客户'">C类客户</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="dialog_footer">
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="saveSetClientGrade">保存</el-button>
          <el-button @click="setClientGradeDialogClosed">取 消</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import uploadFile from '@/components/UploadFile.vue'
import CitySelect from '@/components/CitySelect.vue'
import DicTagButton from '@/components/DicTagButton.vue'

import AddEnterprise from './components/addenterprise.vue'
import {
  listPage,
  files,
  isFreeze,
  notAllocate,
  searchCustomerManager,
  setClientGrade,
  cancelAllocate,
  companyReport
} from '@/api/enterpriseCustomer.js'
import { listOperatorList } from '@/api/login.js'

const operatorId = sessionStorage.getItem('operatorId')
const unitId = sessionStorage.getItem('unitId')
const defaultQueryInfo = Object.freeze({
  operatorId,
  clientGrade: '', // 客户等级
  companyLabel: '', // 标签
  notAllocate: null, // 是否分配 （默认0 0->未分配;1->已分配）
  country: '中国',
  district: '',
  city: '贵阳市',
  industryCategoryList: [],
  province: '贵州省',
  customerId: '',
  unitId,
  pageNum: 1,
  pageSize: 10,
  orders: 1, // 默认传1 2->升序;3->降序
  keys: '' //  企业名/客户经理名
})

const defaultManagerQueryInfo = Object.freeze({
  operatorId,
  city: '贵阳市', // 市
  district: '', // 区
  provinces: '贵州省', // 省
  pageNum: 1,
  pageSize: 10,
  orders: 1, // 排序 默认传1 2->关联企业升序;3->关联企业降序;4->登陆时间升序;5->登陆时间降序
  keys: '' //  姓名/电话
})

export default {
  name: 'EnterpriseCustomer',
  // uploadLogo
  components: { CitySelect, AddEnterprise, DicTagButton },
  data() {
    return {
      industryName: 'industryName',
      tags: [],
      timer: false, // 定时器用
      loading: false,
      clientGrade: 'A类客户', // 选中的客户等级
      setClientGradeDialogVisible: false, // 设置企业等级弹窗
      saveSetManagerBtnDisabled: false, // 禁用分配客户经理保存按钮
      sortVal: 'up', // 排序点击事件的传参
      isActive: '', // 排序图标样式
      uploading: false, // 上传文件loading
      fileUrl: '', // 上传文件路径
      echo: 0, // 0是新增，1是删除
      checkAll: false, //显示全选图标
      isIndeterminate: false, //显示半选图标
      customerId: null, // 选中的客户经理id
      companyIdList: [], // 选中的企业id
      setManagerDialogVisible: false, // 分配客户经理弹窗
      managerQueryInfo: { ...defaultManagerQueryInfo }, // 客户经理查询
      managerTableList: [], // 客户经理列表
      managerTableTotal: 0, // 客户经理总条数
      queryInfo: { ...defaultQueryInfo }, // 企业查询一览
      list: [], // 企业列表
      total: 0 // 企业总条数
    }
  },
  created() {
    // this.search()
    // this.listOperatorList()
  },
  computed: {
    roleId() {
      return JSON.parse(sessionStorage.getItem('roleId'))
    },
    customer() {
      return sessionStorage.getItem('customerId')
    },
    isred() {
      return this.$route.query.isred
    }
  },
  methods: {
    async listOperatorList() {
    
      const operatorIds = sessionStorage.getItem('operatorId') || ''
      if (!operatorIds) {
        let res = await listOperatorList({
          operatorUrl: window.location.origin
        })
       if(res.data){
        sessionStorage.setItem('operatorId', res.data.id)

       }
      
      }
      this.search()
    },
    async search() {
      this.loading = true
      this.queryInfo.operatorId=sessionStorage.getItem('operatorId')||''
      this.queryInfo.customerId = this.customer
      const res = await listPage(this.queryInfo)
      if (res.resultCode === 200) {
        res.data.list.forEach((el) => {
          // el.reportingStatus=2
          if (el.companyLabelList && el.companyLabelList.length) {
            if (el.companyLabelList.length > 3) {
              el.isShowTag = false

              el.tagList = el.companyLabelList.filter((i1, i2) => i2 < 3)
            }
          }
        })
        this.list = res.data.list

        this.total = res.data.total
        this.loading = false
        // this.$message.success('获取企业信息成功!')
        this.handleCheckAllChange(false)
      } else {
        this.$message.warning('获取企业信息失败!')
        this.loading = false
      }
    },
    onChange(firstVal, val) {
      
      // if (val) {
      //   this.queryInfo.industryCategoryList = val
      // } else {
      //   this.queryInfo.industryCategoryList = []
      // }
      if (firstVal && val) {
        this.queryInfo.industryCategoryList = [firstVal]
        // this.queryInfo.industryCategoryList = val
        this.queryInfo.industryCategoryList = this.queryInfo.industryCategoryList.concat(val)
      } else if (firstVal && !val) {
        this.queryInfo.industryCategoryList = [firstVal]
      } else if (!val && !firstVal) {
        this.queryInfo.industryCategoryList = []
      }
      this.industryName = 'industryName'
      if (val) {
        this.search()
      }
       if(!val&&firstVal){
        this.search()
      }
      if(!val&&!firstVal){
        this.search()
      }

      // console.log(firstVal)
      // console.log(val)
    },
    closeTag() {
      this.queryInfo.industryCategoryList = []
      this.industryName = ''
      this.search()
    },
    //批量报备
    checkClick() {
      if (this.companyIdList.length > 0) {
        const that = this
        this.$alert('是否确认批量报备选中企业?')
          .then(function () {
            return companyReport(that.companyIdList)
          })
          .then(() => {
            that.search()
            that.companyIdList = []
            this.isIndeterminate = false
            this.checkAll = false

            that.$message.success('报备成功')
          })
          .catch(() => {})
      } else {
        this.$message.warning('请选择企业')
      }
    },
    async setClientClns() {
      if (this.companyIdList.length > 0) {
        let query = {
          companyIdList: this.companyIdList,
          customerId: this.customer
        }
        const that = this
        this.$alert('是否确认批量批量取消分配?')
          .then(function () {
            return cancelAllocate(query)
          })
          .then(() => {
            that.search()
            that.companyIdList = []
            this.isIndeterminate = false
            this.checkAll = false

            that.$message.success('取消成功')
          })
          .catch(() => {})
      } else {
        this.$message.warning('请选择企业')
      }
    },
    //诊断记录
    records(row) {
      this.$router.push({
        name: 'Records',
        query: { id: row.id, orgCompanyId: row.orgCompanyId }
      })
    },
    //订单管理
    clickorder(row) {
      this.$router.push({
        name: 'order',
        query: { id: row.id }
      })
      // this.$message.warning('待开发')
    },
    //用户管理
    clcikuser(row) {
      this.$router.push({
        name: 'CorrelateUser',
        query: { id: row.id }
      })
    },
    // 获取标签class
    getTagClass(val) {
      if (val === 'A类客户') {
        return 'bc_color1'
      } else if (val === 'B类客户') {
        return 'bc_color2'
      } else if (val === 'C类客户') {
        return 'bc_color3'
      }
    },
    // 上传企业Excel
    async upload(file) {
      const form = new FormData()
      form.append('operatorId', operatorId)
      form.append('files', file.file)
      const res = await files(form)
      if (res.resultCode === 200) {
        this.$message.success('批量导入企业成功!')
        this.search()
      } else {
        this.$message.warning(res.message)
      }
    },
    // 上传完成前
    beforeUpload(file) {
      this.uploading = true //上传时显示
      // this.form.size = file.size
      // var fileSize = file.size / 1024 / 1024 < 100
      this.$message('正在上传中，请稍等')
    },
    //上传成功回调
    handleSuccess(res, file) {
      console.log(res)
      //后台上传地址
      this.uploading = false //无论是否成功懒加载都隐藏
      // if (res.resultCode == 0) {
      //   this.$message.success('上传成功')
      //   this.form.path = res.resultData
      //   console.log(res.resultData)
      // } else {
      //   this.$message.error('上传失败，请重新上传')
      // }
    },
    // 返回企业类型标签绑定的class

    getEnterpriseScale(val) {
      if (val == 'L(大型)' || val == '大型') {
        return 'bc_color4'
      } else if (val === 'M(中型)' || val == '中型') {
        return 'bc_color5'
      } else if (val === 'S(小型)' || val == '小型') {
        return 'bc_color6'
      } else if (val === 'XS(微型)' || val == '微型') {
        return 'bc_color7'
      }
    },
    //排序
    handleSort(val) {
      this.isActive = val
      if (val === 'up') {
        this.queryInfo.orders = 2
        this.sortVal = 'down'
        this.search()
      } else {
        this.queryInfo.orders = 3
        this.sortVal = 'up'
        this.search()
      }
    },
    //是否只展示未分配客户经理的企业
    showNotAllocate(val) {
      if (!val) {
        this.queryInfo.notAllocate = null
      } else {
        this.queryInfo.notAllocate = false
      }
      this.search()
    },
    //全选框
    handleCheckAllChange(bol) {
      //bol为布尔值，true是选中，false是取消
      if (bol) {
        this.list.forEach((item) => {
          //orgCompanyId
          this.companyIdList.push(item.id)
          this.$set(item, 'checked1', true)
        })
      } else {
        this.list.forEach((item) => {
          this.companyIdList = []
          this.checkAll = false
          this.$set(item, 'checked1', false)
        })
      }

      this.isIndeterminate = false
    },
    // 多选框选中数据
    latechange(bol, row) {
      // orgCompanyId
      const index = this.companyIdList.indexOf(row.id)
      if (index == -1) {
        //orgCompanyId
        this.companyIdList.push(row.id)
      } else {
        this.companyIdList.splice(index, 1)
      }
      if (bol) {
        if (this.companyIdList.length === this.list.length) {
          this.isIndeterminate = false
          this.checkAll = true
        } else {
          this.isIndeterminate = true
          this.checkAll = false
        }
      } else {
        if (this.companyIdList.length < 1) {
          this.isIndeterminate = false
          this.checkAll = false
        } else {
          this.isIndeterminate = true
          this.checkAll = false
        }
      }
    },
    //监听pagesize变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.search()
    },
    //监听页码变化
    handleCurrentChange(newCurrent) {
      this.queryInfo.pageNum = newCurrent
      this.search()
    },
    //返回省
    // searchProvince(val) {
    //   this.queryInfo.province = val
    //   this.search()
    // },
    //返回城市
    searchCity(val) {
      this.queryInfo.city = val
      this.listOperatorList()
    },
    //返回区/县
    searchDistrict(val) {
      this.queryInfo.district = val
      
      this.listOperatorList()
    },
    // 打开分配客户经理弹窗
    showSetManagerDialog(id) {
      if (typeof id === 'number') {
        this.companyIdList.push(id)
      }
      if (this.companyIdList.length < 1) {
        this.$message.warning('请先选择企业!')
      } else {
        this.managerQueryInfo = { ...defaultManagerQueryInfo }
        this.searchCustomerManager()
        this.setManagerDialogVisible = true
      }
    },

    // 查询客户经理
    async searchCustomerManager() {
      const res = await searchCustomerManager(this.managerQueryInfo)
      if (res.resultCode === 200) {
        this.managerTableList = res.data.list
        this.managerTableTotal = res.data.total
        // this.$message.success('获取客户经理信息成功!')
      } else {
        this.$message.warning('获取客户经理信息失败!')
      }
    },
    //监听pagesize变化  分配客户经理弹窗
    handleSizeChangeManager(newSize) {
      this.managerQueryInfo.pageSize = newSize
      this.searchCustomerManager()
    },
    //监听页码变化 分配客户经理弹窗
    handleCurrentChangeManager(newCurrent) {
      this.managerQueryInfo.pageNum = newCurrent
      this.searchCustomerManager()
    },
    //返回省  分配客户经理弹窗
    searchProvinceManager(val) {
      this.managerQueryInfo.provinces = val
      this.searchCustomerManager()
    },
    //返回城市 分配客户经理弹窗
    searchCityManager(val) {
      this.managerQueryInfo.city = val
      this.searchCustomerManager()
    },
    //返回区/县 分配客户经理弹窗
    searchDistrictManager(val) {
      this.managerQueryInfo.district = val
      this.searchCustomerManager()
    },
    // 选中table项 分配客户经理弹窗
    handleSelect(selection, val) {
      //只能选择一行，选择其他，清除上一行
      if (selection.length > 1) {
        let del_row = selection.shift()
        this.$refs.managerTable.toggleRowSelection(del_row, false) //设置这一行取消选中
      }
      this.customerId = val.id
    },
    // 排序
    sortChange(val) {
      if (val.prop === 'companyCount') {
        if (val.order === 'ascending') {
          this.managerQueryInfo.orders = 2
        } else if (val.order === 'descending') {
          this.managerQueryInfo.orders = 3
        } else {
          this.managerQueryInfo.orders = 1
        }
      } else if (val.prop === 'loginTime') {
        if (val.order === 'ascending') {
          this.managerQueryInfo.orders = 4
        } else if (val.order === 'descending') {
          this.managerQueryInfo.orders = 5
        } else {
          this.managerQueryInfo.orders = 1
        }
      }
      this.searchCustomerManager()
    },
    // 关闭分配客户经理弹窗
    setManagerDialogClosed() {
      this.setManagerDialogVisible = false
      this.saveSetManagerBtnDisabled = false
      this.$refs.child.clear()
      this.handleCheckAllChange(false)
      this.companyIdList = []
    },
    //保存按钮
    async saveSetManager() {
      this.saveSetManagerBtnDisabled = true
      let params = {
        companyIdList: this.companyIdList,
        customerId: this.customerId
      }
      const res = await notAllocate(params)
      if (res.resultCode === 200) {
        this.setManagerDialogVisible = false
        this.saveSetManagerBtnDisabled = false
        this.$message.success('分配客户经理成功!')
        this.companyIdList = []
        this.handleCheckAllChange(false)
        this.search()
      } else {
        this.$message.warning('分配客户经理失败!')
        this.saveSetManagerBtnDisabled = false
      }
    },
    // 打开新增企业弹窗
    add() {
      this.echo = 0
      this.$refs.addRef.addDialogVisible = true
    },
    // 打开设置等级弹窗
    setClientGrade(id) {
      if (typeof id === 'number') {
        this.companyIdList = [id]
      }
      if (this.companyIdList.length > 0) {
        this.setClientGradeDialogVisible = true
      } else {
        this.$message.warning('请先选择企业!')
      }
    },
    // 保存设置等级
    async saveSetClientGrade() {
      const param = {
        clientGrade: this.clientGrade,
        companyId: this.companyIdList
      }
      console.log(param)
      const res = await setClientGrade(param)
      if (res.resultCode === 200) {
        this.$message.success('批量设置等级成功!')
        this.handleCheckAllChange(false)
        this.setClientGradeDialogClosed()
        this.search()
      } else {
        this.$message.warning('批量设置等级失败!')
      }
    },
    // 关闭设置等级弹窗
    setClientGradeDialogClosed() {
      this.handleCheckAllChange(false)
      this.setClientGradeDialogVisible = false
      this.clientGrade = 'A类客户'
    },
    // 冻结、解冻
    async setFreeze(id, bol) {
      let val = `${bol ? '解冻' : '冻结'}`
      const res = await isFreeze({ id })
      if (res.resultCode === 200) {
        this.$message.success(val + '成功!')
        this.search()
      } else {
        this.$message.warning(val + '失败!')
      }
    },
    //跳转
    toEnterpriseDetails(item, val) {
      this.$router.push({
        name: 'EnterpriseDetails',
        // path: '/enterprisedetails',
        query: {
          id: item.id,
          val,
          customerId: item.customerId,
          orgCompanyId: item.orgCompanyId,
          companyFullName: item.companyFullName
        }
      })
    },
    // 跳转官网
    toOfficialWebsite(val) {
      window.open(val)
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner {
    color: #fff;
    background-color: #448aff;
    border-color: #448aff;
    box-shadow: -1px 0 0 0 #448aff;
    border-radius: 4px;
}
::v-deep .el-checkbox-button.is-checked .el-checkbox-button__inner {
    margin: 1px;
    color: #fff;
    background-color: #448aff;
    border-color: #448aff;
    box-shadow: -1px 0 0 0 #8fb9ff;
    border-radius: 4px;
}
::v-deep .el-form-item {
    margin-bottom: 12px;
}
.customer_manager {
  height: calc(100% - 60px);
}
::v-deep .el-table__header-wrapper .el-checkbox {
  display: none;
}
.el-form-item ::v-deep .el-form-item__label {
  color: #7c7f8e;
}
.el-form-item ::v-deep .el-form-item__content {
  display: flex;
}
.distpicker-address-wrapper ::v-deep select {
  width: 150px;
}
.distpicker-address-wrapper ::v-deep label {
  margin-right: 10px;
}
.el-form-item__content ::v-deep .upload-demo {
  width: 100px;
}
.new_page {
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  border-top: #fafafa 1px solid;
  text-align: center;
}
.search_right {
  width: 460px;
  margin-right: 20px;
  /deep/ .el-button {
    border: 1px solid #448aff;
    color: #fff;
    border-radius: 0;
    background-color: #448aff;
  }
}
.check {
  margin-left: auto;
  margin-right: 30px;
}
.btn_list {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-button {
    margin-right: 10px;
  }
  span {
    margin-left: 10px;
    cursor: pointer;
  }
}
.row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.icon_box {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  img {
    width: 16px;
    height: 16px;
  }
  i {
    font-size: 16px;
  }
}
.el-radio-button ::v-deep .el-radio-button__inner {
  border-radius: 4px !important;
  border: 0 !important;
}

.sort {
  display: flex;
  align-items: center;
  margin-left: 20px;
  span {
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;
    margin-right: 5px;
  }
  .sort_icon {
    cursor: pointer;
    display: flex;
    flex-flow: column;
    height: auto;
    i {
      width: 14px;
      height: 14px;
      color: #dbd6d6;
      font-size: 14px;
    }
    .isActive {
      color: #8a8888 !important;
    }
  }
}

.top_card {
  display: flex;
  flex-flow: column;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 10px 20px;
  box-sizing: border-box;
  .btn_list {
    margin-left: 20px;
    margin-right: 20px;
  }
  .radio_group {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .wrap_left {
      width: 90%;
    }
    .el-radio-group {
      display: flex;
      flex-flow: row wrap;
    }
    ::v-deep .el-radio-button {
      .el-radio-button__inner {
        border-radius: 4px !important;
        border: 0 !important;
      }
    }
  }
}

.card_list {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: calc(100vh - 420px);
  overflow-y: auto;

  /* 滚动条整体部分 */
  &::-webkit-scrollbar {
    width: 3px;
    height: 9px;
  }
  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }
  .po_reportingStatus {
    position: absolute;
    color: #f53939;
    top: 50%;
    transform: translateY(-50%);
    left: 28%;
    z-index: 999 !important;
    img {
      width: 76px;
      height: 60px;
    }
  }
  .card_item {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #fff;
    margin-bottom: 10px;
    padding: 10px 20px;
    box-sizing: border-box;
    position: relative;
    .card_left {
      display: flex;
      align-items: center;
      padding-top: 10px;
      box-sizing: border-box;
      width: 550px;
      margin-right: 20px;
      .item_checkbox {
        line-height: 36px;
        margin: 0px 10px;
      }
      .img_box {
        width: 100px;
        height: 100px;
        margin-right: 10px;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        img {
          width: 100px;
          height: 100px;
        }
        .tag_box {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 60px;
          height: 20px;
          color: #fff;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          // background-color: #198754;
          // border-top-left-radius: 8px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          position: absolute;
          top: 10px;
          left: 0;
          z-index: 999;
        }
      }
      .info_box {
        display: flex;
        justify-content: center;
        flex-flow: column;
        .enterprise_name {
          cursor: pointer;
          font-weight: bold;
          font-size: 18px;
          line-height: 24px;
          color: #000;
          max-width: 340px; /* 固定宽度超出隐藏 */
          overflow: hidden; /*  超出隐藏 */
          text-overflow: ellipsis; /*  超出以 ...显示 */
          display: -webkit-box;
          -webkit-line-clamp: 1; /* // 显示1行 */
          -webkit-box-orient: vertical;
        }
        .enterprise_name:hover {
          color: #448aff;
        }
        .type_tag {
          font-size: 10px;
          color: #fff;
          text-align: center;
          margin-left: 10px;
          padding: 5px;
          border-radius: 2px;
        }

        .tag_list {
          display: flex;
          .tag_item {
            font-size: 10px;
            color: #4e93fb;
            border: #559eff 1px solid;
            border-radius: 4px;
            padding: 5px;
            margin-right: 6px;
          }

          .show_more {
            position: relative;
            font-size: 14px;
            line-height: 26px;
            text-align: center;
            cursor: pointer;
            .show_tag_box {
              position: absolute;
              top: 100%;
              left: 100%;
              z-index: 99;
              width: 240px;
              padding: 6px;
              box-sizing: border-box;
              border-radius: 4px;
              background-color: #fff;
              border: #f8f8f8 1px solid;
              display: flex;
              flex-flow: row wrap;
              li {
                padding: 3px 5px;
                .tag {
                  width: fit-content;
                  font-size: 10px;
                  line-height: 20px;
                  color: #4e93fb;
                  border: #559eff 1px solid;
                  border-radius: 4px;
                  padding: 0 6px;
                  box-sizing: border-box;
                }
              }
            }
          }
        }

        .website {
          cursor: pointer;
          font-size: 14px;
          color: #448aff;
        }
        .website:hover {
          text-decoration: underline;
        }
      }
    }
    .card_right {
      flex: 1;
      display: flex;
      .box_list {
        display: flex;
        align-items: center;
        .item {
          cursor: pointer;
          display: flex;
          flex-flow: column;
          justify-content: center;
          text-align: center;
          margin-right: 30px;
          .num {
            font-weight: bold;
            font-size: 24px;
            color: #151515;
            margin-bottom: 10px;
          }
          .txt {
            font-size: 16px;
            color: #151515;
          }
        }
        .item:hover {
          .num {
            color: #448aff;
          }
          .icon_box {
            color: #448aff;
          }
          span {
            color: #448aff;
          }
        }
      }
      .btn_box {
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: 10px;
        span:hover {
          opacity: 0.7;
        }
        .divs {
          margin-right: 10px;
        }
        .set_manager {
          display: flex;
          align-items: center;
          min-width: 120px;
          margin-right: 0px;
          .middle_icon_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background-color: #f4f4f4;
            overflow: hidden;
            margin-right: 10px;
            img {
              width: 35px;
              height: 35px;
            }
            i {
              font-size: 16px;
              color: #c9c9c9;
            }
          }
          .manager_name,
          .wait_set {
            cursor: pointer;
            text-decoration: underline;
            font-size: 16px;
          }
        }
        .btn_list span {
          font-size: 14px;
        }
      }
    }
  }
}
.reportingAgin {
  color: #3675f6;
  border-radius: 8px;
  padding: 5px;
  font-size: 14px;
  background: rgba(195, 212, 245, 0.4);
  margin-right: 5px;
}
.reportingEnd {
  color: #11b67e;
  border-radius: 8px;
  padding: 5px;
  font-size: 14px;
  background: rgba(199, 230, 218, 0.4);
  margin-right: 5px;
}
.fail {
  color: #f56c6c;
  background: #fef0f0;
  border-color: #fbc4c4;
  border-radius: 8px;
  padding: 5px;
  font-size: 14px;
  margin-right: 5px;
}
.reporting {
  border-radius: 8px;
  padding: 5px;
  font-size: 14px;
  color: #ff8c06;
  background: #fff4e6;
  margin-right: 5px;
}
.success {
  width: 66px;
  border-radius: 8px;
  padding: 5px;
  font-size: 14px;
  color: #2f9834;
  background: #e5f5e7;
  margin-right: 5px;
}
.dialog_box {
  display: flex;
  flex-flow: column;
  min-height: 80px;
}
.table {
  overflow-y: auto;
  height: 300px;
  margin-top: 10px;
  .customer_head {
    width: 42px;
    height: 42px;
  }
}
.dialog_footer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #e1e1e1;
  padding: 20px;
}
.el-form-item .form_radio_group {
  display: flex;
  align-items: center;
  height: 40px;
}
.el-form-item .input_box {
  width: 80%;
}

.color1 {
  color: #4e93fb;
  font-size: 16px;
}
.color2 {
  color: #fd5469;
  font-size: 16px;
}
.color3 {
  color: #fc8b39;
  font-size: 16px;
}
</style>
