import request from '@/utils/request'

// 登录
export function login(data) {
  return request({
    url: '/auth/v1/login',
    method: 'post',
    data
  })
}
//获取验证码
export function getSms(query) {
  return request({
    url: '/auth/v1/getSms',
    method: 'get',
    params: query
  })
}
// 根据url查询运营商信息
export function listOperatorList(data) {
  return request({
    url: '/firstTierOperator/listOperatorList',
    baseURL: process.env.VUE_APP_BASE_APITWO,
    method: 'post',
    data
  })
}
